import React, { useState, useMemo } from "react";
import { sortBy } from "lodash";
import moment from "moment";

import Modal from "./Modal";

const DAYS_UNTIL_ARCHIVED = 14;

const getProgressNotes = () => {
  const noteCount = Math.floor(Math.random() * 3) + 1;
  const noteArray = [];

  for (let x = 0; x < noteCount; x++) {
    noteArray.push({
      id: x + 1,
      note:
        "Lorem ipsum odor amet, consectetuer adipiscing elit. Egestas netus quis parturient nam pellentesque maximus. Mattis euismod purus scelerisque himenaeos dui curabitur; non tempus volutpat. Aptent pharetra pulvinar parturient ultricies risus tortor tincidunt magna phasellus.",
    });
  }

  return noteArray;
};

const THIRTY_DAYS_AGO = moment().subtract(30, "days");

const ServiceItem = ({ id, description, orders = [], diagnoses = [] }) => {
  const [open, setOpen] = useState(false);
  const [oldItemsOpen, setOldItemsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const mergedItems = orders.concat(diagnoses);
  const mergedGroupedByDate = getItemsGroupedByDate(mergedItems);

  const expandable = useMemo(() => {
    return orders.length > 0 || diagnoses.length > 0;
  }, [orders, diagnoses]);

  const hasOldItems = useMemo(() => {
    return expandable && Object.keys(mergedGroupedByDate.older).length > 0;
  });

  const toggle = () => {
    if (expandable) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const oldItemsToggle = () => {
    if (hasOldItems) {
      setOldItemsOpen((x) => !x);
    }
  };

  const chooseColor = (mergedItems) => {
    for (let o of mergedItems) {
      if (moment().diff(moment(o.date), "days") <= DAYS_UNTIL_ARCHIVED) {
        return "#7AECEB";
      }
    }
    return "none";
  };

  let notes = [];

  if (expandable) {
    notes = getProgressNotes();
  }

  return (
    <>
      <div key={id} className="panel panel-default m-b-xxs">
        <div
          className={`panel-heading d-flex ${expandable ? "clickable" : ""}`}
          style={{
            backgroundColor: expandable ? chooseColor(mergedItems) : "none",
          }}
          onClick={toggle}
        >
          <span className="flex-1-auto">{description}</span>
          {expandable && (
            <button type="button" className="btn-link" onClick={(e) => e.stopPropagation()}>
              Services Delivered <i className={`fa fa-${open ? "minus" : "plus"}-square`} />
            </button>
          )}
        </div>
        {expandable && open && (
          <div className="panel-body">
            {Object.keys(mergedGroupedByDate.recent).length > 0 && (
              <div>{getItemList(mergedGroupedByDate.recent, notes, () => setIsModalOpen((x) => !x))}</div>
            )}
            {hasOldItems && (
              <div>
                <button class="btn-link" onClick={oldItemsToggle}>
                  {oldItemsOpen ? "Hide" : "Show"} Old Items
                </button>
                {oldItemsOpen && (
                  <div class="m-t-sm">
                    {getItemList(mergedGroupedByDate.older, notes, () => setIsModalOpen((x) => !x))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} title="Progress Notes">
        <div>
          {notes.map((n) => {
            return (
              <div key={n.id}>
                <p>{n.note}</p>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

const getItemsGroupedByDate = (items) => {
  return items.reduce(
    (acc, item) => {
      const targetGroup = moment(item.date).isBefore(THIRTY_DAYS_AGO) ? "older" : "recent";

      if (!acc[targetGroup][item.date]) {
        acc[targetGroup][item.date] = [];
      }

      acc[targetGroup][item.date].push(item);
      return acc;
    },
    {
      recent: {},
      older: {},
    }
  );
};

const getItemList = (items, notes, callbackFn) => {
  return (
    <ul>
      {Object.keys(items).map((date) => (
        <li className="m-b-sm" key={date}>
          <b>{moment(date).format("M/DD/YYYY")}</b>
          <ul>
            {items[date].map((item) => (
              <li className="m-b-sm" key={item.id}>
                {item.description}
              </li>
            ))}
            <li className="m-b-sm">
              <span
                className="clickable"
                onClick={callbackFn}
                style={{ color: "#337ab7", textDecoration: "underline" }}
              >
                View {notes.length} Related Progress Notes
              </span>
            </li>
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default function ServiceSummary({ plans = [], carveOuts = [] }) {
  return (
    <div>
      {plans.length <= 0 && carveOuts.length <= 0 && <p>No services are expected at this time.</p>}
      <div className="row">
        {plans.length > 0 && (
          <div className={carveOuts.length > 0 ? "col-sm-7 col-md-6" : "col-xs-12"}>
            {sortBy(plans, [(plan) => plan.rank]).map((plan) => {
              return (
                <React.Fragment key={plan.id}>
                  <h4>{plan.careLevel}</h4>
                  <p>{plan.description}</p>
                  {plan.medicallyComplexCareEvents.map((mcce) => (
                    <ServiceItem key={`${mcce.id}${mcce.description}`} {...mcce} />
                  ))}
                </React.Fragment>
              );
            })}
          </div>
        )}
        {carveOuts.length > 0 && (
          <div className={plans.length > 0 ? "col-sm-5 col-md-6" : "col-xs-12"}>
            <h4>Other Billable Items</h4>
            {carveOuts.map((co) => {
              return <ServiceItem {...co} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
}
